import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import * as style from './style/404.module.scss'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id = { style.NotFound }>
    	<div className = 'wrapper'>
    		<h1>NOT FOUND</h1>
    		<p>You just hit a route that doesn&#39;t exist... 
    		<br/><Link to = '/'>Go back to Saraï's work.</Link>
    		</p>
    	</div>
    </div>
  </Layout>
)

export default NotFoundPage


